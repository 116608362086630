<template>
  <div class="modal-backdrop" @click.self="close">
    <div class="modal columnd gap32">
      <div class="columnd gap16">
        <h1 class="fBold f22-700 c-lightOrange text-start">Withdraw</h1>
        <p class="f14-400 c-darkGrey">
          Your request will Aprove and pay manually to you. Transaction fee wil
          be counted on withdraw amount
        </p>
      </div>
      <div class="columnd gap16 w100">
        <div class="selectCoin w100">
          <span class="f12-700 c-darkGrey text-start fBold">Network</span>
          <div class="rowd w100 space-between align-center">
            <input type="text" placeholder="" />
            <inline-svg
              class="medgrey-svg"
              :src="require('../../../assets/Icons/arrow.svg')"
            />
          </div>
        </div>
        <div class="selectCoin w100">
          <span class="f12-700 c-darkGrey text-start fBold">Amount</span>
          <input type="text" placeholder="" />
        </div>
        <div class="selectCoin w100">
          <span class="f12-700 c-darkGrey text-start fBold"
            >Destination Wallet Address</span
          >
          <input type="text" placeholder="" />
        </div>
        <div class="columnd gap16">
          <div class="rowd gap8 align-center">
            <inline-svg :src="require('../../../assets/Icons/Warning.svg')" />
            <div class="rowd gap4">
              <span class="f12-400 c-ddGrey">Network Fee </span>
              <span class="f12-700 c-ddGrey">0.00003 BTC</span>
            </div>
          </div>
          <div class="rowd gap8 align-center">
            <inline-svg :src="require('../../../assets/Icons/Warning.svg')" />
            <div class="rowd gap4">
              <span class="f12-400 c-ddGrey">Minimum Amount of withdraw </span>
              <span class="f12-700 c-ddGrey">0.00003 BTC</span>
            </div>
          </div>
        </div>
      </div>
      <button
        @click.prevent="confirm"
        class="primary-btn align-self-end justify-center"
      >
        Withdraw
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "WidthDrawal",
  methods: {
    confirm() {
      this.$emit("confirm");
      this.$emit("close");
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  padding: 32px 24px;
}
</style>
