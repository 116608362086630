<template>
  <div class="mini-container columnd gap48 align-self-start w100">
    <h1 class="fBold f22-700 c-lightOrange text-start">Wallet</h1>
    <Wallets
      @openWithDraw="withDrawalModal = true"
      @openDeposite="Deposite = true"
    />
    <WithdrawalModal
      v-if="withDrawalModal"
      @confirm="otp = true"
      @close="withDrawalModal = false"
    />
    <DepositeModal
      v-if="Deposite"
      @confirm="otp = true"
      @close="Deposite = false"
    />
    <OtpModal @close="otp = false" @confirm="otp = false" v-if="otp" />
  </div>
</template>

<script>
import OtpModal from "../../BuySell/Negotiation/OtpModal.vue";
import Wallets from "./Wallets.vue";
import WithdrawalModal from "./WithdrawalModal.vue";
import DepositeModal from "./DepositeModal.vue";
export default {
  name: "Wallet",
  components: {
    Wallets,
    WithdrawalModal,
    DepositeModal,
    OtpModal,
  },
  data() {
    return {
      otp: false,
      withDrawalModal: false,
      Deposite: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.mini-container {
  overflow-x: scroll;
}
</style>
