<template>
  <div class="columnd gap12 w100 walletable">
    <div class="header rowd w100">
      <span style="flex: 1" class="f12-700 fBold c-darkGrey text-start"
        >Coin</span
      >
      <span style="flex: 1" class="f12-700 fBold c-darkGrey text-start"
        >Amount</span
      >
      <span style="flex: 1" class="f12-700 fBold c-darkGrey text-start"
        >Avg.Trade Speed</span
      >
      <span style="flex: 1.7" class="f12-700 c-darkGrey"></span>
    </div>
    <div class="wallet-container columnd gap12 w100">
      <div v-for="i in 10" :key="i" class="item rowd w100">
        <div style="flex: 1" class="rowd gap12">
          <img class="coin40" src="../../../assets/test/ETHEREUM.png" alt="" />
          <div class="columnd gap4">
            <span class="text-start f14-700 c-ddGrey">ETHEREUM</span>
            <span class="text-start f12-400 c-darkGrey">ETH</span>
          </div>
        </div>
        <div style="flex: 1" class="rowd gap4 align-center">
          <span class="text-start f14-400 c-ddGrey">73,732</span>
          <span class="text-start f14-400 c-medGrey">ETH</span>
        </div>
        <div style="flex: 1" class="rowd gap4 align-center">
          <span class="text-start f14-400 c-ddGrey">7,367,333,000</span>
          <span class="text-start f14-400 c-medGrey">USD</span>
        </div>
        <div class="rowd gap32 align-center justify-end" style="flex: 1.7">
          <a
            class="withdraw c-lightOrange f12-700 fBold"
            @click.prevent="openWithDraw"
            href=""
            >Withdraw</a
          >
          <a
            class="deposite c-darkBlue f12-700 fBold"
            @click.prevent="openDeposite"
            href=""
            >Deposit</a
          >
          <button class="primary-btn">Trade</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WalletsList",
  methods: {
    openWithDraw() {
      this.$emit("openWithDraw");
    },
    openDeposite() {
      this.$emit("openDeposite");
    },
  },
};
</script>

<style lang="scss" scoped>
.walletable {
  min-width: 800px;
}
.wallet-container {
  max-height: 600px;
  overflow: scroll;
}
.withdraw {
  padding: 12px 16px;
  border-radius: 8px;
  &:hover {
    background-color: var(--lightOrange) !important;
    color: var(--white) !important;
  }
}
.deposite {
  padding: 12px 16px;
  border-radius: 8px;
  &:hover {
    background-color: var(--lightCryan) !important;
    color: var(--white) !important;
  }
}
.primary-btn {
  max-width: 100px;
}
.item {
  padding: 16px;
  border-bottom: 1px solid var(--lightGrey);
  border-radius: 8px;
  &:hover {
    cursor: pointer;
    background-color: var(--llGrey);
  }
}
.header {
  padding: 12px 8px;
  border-bottom: 1px solid var(--lightGrey);
}
</style>
