<template>
  <div class="modal-backdrop" @click.self="close">
    <div class="modal columnd gap32">
      <div class="columnd gap16">
        <h1 class="fBold f22-700 c-lightOrange text-start">Withdraw</h1>
        <p class="f14-400 c-darkGrey">
          This your wallet Address. share it to receive Crypto currency
        </p>
      </div>
      <div class="img-container columnd align-center align-self-center">
        <img src="../../../assets/test/Barcode.svg" alt="" />
        <div class="coin48">
          <img class="w100" src="../../../assets/test/ETHEREUM.png" alt="" />
        </div>
      </div>
      <div class="columnd gap16 w100">
        <div class="selectCoin w100">
          <span class="f12-700 c-darkGrey text-start fBold">Network</span>
          <div class="rowd w100 space-between align-center">
            <input type="text" placeholder="" />
            <inline-svg
              class="medgrey-svg"
              :src="require('../../../assets/Icons/arrow.svg')"
            />
          </div>
        </div>
        <div class="selectCoin w100">
          <span class="f12-700 c-darkGrey text-start fBold"
            >Wallet Address</span
          >
          <input type="text" placeholder="" />
        </div>
      </div>
      <button
        @click.prevent="confirm"
        class="primary-btn align-self-end justify-center"
      >
        Deposite
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "DepositeModal",
  methods: {
    confirm() {
      this.$emit("confirm");
      this.$emit("close");
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.img-container {
  border-radius: 24px;
  border: 1px solid var(--llGrey);
  z-index: 3;
  .coin48 {
    padding: 8px;
    transform: translateY(10px);
    border-radius: 50%;
    position: relative;
    border: 1px solid var(--llGrey);
    z-index: 10;
    img {
      z-index: 2;
    }
    &::before {
      position: absolute;
      content: " ";
      background-color: white;
      width: 49px;
      height: 48px;
      top: -2px;
      left: -1.5px;
      z-index: -1;
      border-radius: 50%;
    }
  }
}
.modal {
  padding: 32px 24px;
}
</style>
